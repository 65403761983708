import "./root.css";
import React from "react";

export default function Root() {
    return (
        <div className="root">
            <header className="root-header">
                <h1>Hello world!</h1>
            </header>
        </div>
    );
}